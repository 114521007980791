<template>
  <v-card>
    <v-card-title>
      <div
        style="width: 100%;"
      >
        <chip
          v-if="isQuickEntry"
          color="primary"
          :close="true"
          :close-icon="icons.mdiClose"
          :icon="icons.mdiInformationOutline"
          :value="'Quick entry is only applicable to wound care practice types and requires a date of service set to the current date.'"
          :label="true"
          icon-color="white"
          class="mb-10 chip"
          style="height: fit-content; width:100%;"
        >
        </chip>
      </div>
      {{ title }}
      <v-spacer></v-spacer>
      <patient-name
        :patient="patient"
      ></patient-name>
    </v-card-title>
    <v-card-subtitle
      class="pb-0"
      style="text-align: right;"
    >
      <patient-info
        :patient="patient"
      ></patient-info>
    </v-card-subtitle>
    <alert
      v-if="patient.no_insurance"
      class="ma-5"
    >
      Patient is uninsured or patient’s insurance does not authorize services.
      Please discuss the need for approval or authorization for future visits with facility staff.
      Contact billing with any questions.
    </alert>
    <v-card-text>
      <v-form
        ref="createEncounterForm"
        v-model="formValid"
        :disabled="!baseDataLoaded"
      >
        <select-box
          v-model="encounter.practice_type_id"
          :items="practiceTypeTemplates"
          label="Practice Type"
          class="mt-5"
          required
          :disabled="isQuickEntry"
          @change="$nextTick(() => {selectVisitType(previousEncounter)})"
        >
        </select-box>
        <select-box
          v-model="encounter.place_of_service_id"
          :items="facilities"
          label="Facility"
          required
        >
        </select-box>
        <date-picker
          v-model="encounter.visit_date"
          :disabled="isQuickEntry"
          :min="getMinDate"
          :max="$date().format('YYYY-MM-DD')"
          label="Visit Date"
          required
          @input="$nextTick(() => {selectVisitType(previousEncounter)})"
        ></date-picker>
        <radio-buttons
          v-model="encounter.visit_type"
          :items="visitTypes"
          label="Visit Type"
          columns="4"
          disabled
        >
          <help-icon
            title="Visit Type Automatically Selected"
            body="<p>Visit type is automatically selected based on the existence of a previous encounter in the last 36 months for this patient and practice type.</p>"
          ></help-icon>
        </radio-buttons>
        <radio-buttons
          v-model="encounter.visit_location"
          :items="visitLocations"
          label="Visit Location"
          columns="4"
          required
        ></radio-buttons>
        <!-- Start: TXP-136 hide until later release -->
        <checkbox
          v-show="false"
          v-if="!isQuickEntry"
          v-model="encounter.is_deferred_care"
          label="Defer patient care?"
        ></checkbox>
        <text-area
          v-if="!isQuickEntry && encounter.is_deferred_care"
          v-model="encounter.deferred_care_reason"
          label="Enter reason patient care was deferred..."
          class="col-sm-12"
          required
        ></text-area>
        <!-- End: TXP-136 -->
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!-- cancel -->
      <btn
        label="Cancel"
        color="secondary"
        :icon="icons.mdiCancel"
        @click="cancelEncounter"
      ></btn>

      <v-spacer></v-spacer>

      <!-- create quick entry -->
      <btn
        v-if="isQuickEntry"
        :disabled="!formValid"
        label="Create Quick Entry"
        :icon="icons.mdiClockPlus"
        @click="createEncounter"
      ></btn>

      <!-- create deferred care encounter -->
      <btn
        v-else-if="encounter.is_deferred_care"
        :disabled="!formValid"
        label="Create Deferred Care Encounter"
        :icon="icons.mdiAccountOff"
        @click="createEncounter"
      ></btn>

      <!-- create encounter -->
      <btn
        v-else
        :disabled="!formValid"
        label="Create Encounter"
        :icon="icons.mdiAccountMultiplePlus"
        @click="createEncounter"
      ></btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import '@/components/patients/primitives'
import EncounterCreation from '@/mixins/EncounterCreation'
import {
  mdiAccountMultiplePlus,
  mdiAccountOff,
  mdiCancel,
  mdiClockPlus,
  mdiClose,
  mdiInformationOutline,
} from '@mdi/js'
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  mixins: [EncounterCreation],
  data() {
    return {
      chip: true,
      formValid: false,
      dos_ack: 0,
      patient: {},
      icons: {
        mdiAccountMultiplePlus,
        mdiCancel,
        mdiClose,
        mdiClockPlus,
        mdiAccountOff,
        mdiInformationOutline,
      },
    }
  },
  computed: {
    ...mapGetters('route', ['lastRoute']),
    ...mapGetters('auth', ['facilities']),
    ...mapGetters('baseData', ['practiceTypeTemplates']),
    ...mapGetters('encounters', ['baseDataLoaded', 'visitLocations', 'visitTypes']),
    isQuickEntry() {
      return this.$route.query
        && String(this.$route.query.quickEntry).toLowerCase() === 'true'
        && this.encounter.practice_type_id === 1
    },
    title() {
      return this.isQuickEntry ? 'Create Quick Entry' : 'Create Encounter'
    },
    getMinDate() {
      if (this.isQuickEntry) return this.$date().format('YYYY-MM-DD')

      return this.$date().subtract(270, 'day').format('YYYY-MM-DD')
    },
    previousEncounter() {
      return this.$store.getters['encounters/previousEncounter'](this.patient.id, this.encounter.practice_type_id)
    },
  },
  watch: {
    'encounter.visit_date': {
      handler(date, newDate) {
        const todayDate = new Date()

        todayDate.setHours(0, 0, 0, 0)

        const inputDate = new Date(newDate)

        if (inputDate < todayDate && this.isQuickEntry) {
          this.$store.dispatch('notify', { value: "The quick entry visit date must be set to today's date.", color: 'error' })
          this.encounter.visit_date = this.$date().format('YYYY-MM-DD')
        }
      },
    },
  },
  mounted() {
    if (this.patient.is_deceased) {
      this.$store.dispatch('notify', { value: 'Can\'t create encounter (patient deceased).', color: 'error' })
      this.$router.push({ name: 'encounter-patient-find' })
    }

    if (!this.$route.query.id) {
      this.$store.dispatch('notify', { value: 'Search for patient to create an encounter note.' })
      this.$router.push({ name: 'encounter-patient-find' })
    } else {
      this.patient = { ...this.$store.getters['patients/getById'](this.$route.query.id) }
    }
    this.primeEncounter()
    this.selectVisitType(this.previousEncounter)

    // If coming from the Quick Entry page, create the Quick Entry record automatically
    if (this.isQuickEntry && this.formValid && this.lastRoute.name === 'quick-entry') {
      this.createEncounter()
    }
  },
  methods: {
    formatDate(dateTime) {
      return this.$date(dateTime).format('M/D/YYYY')
    },
    createEncounter() {
      // Used for follow-up visit and confirmVisitDate method
      // const previousEncounter = this.$store.getters['encounters/previousEncounter'](this.patient.id, this.encounter.practice_type_id, this.encounter.visit_date)

      // Inform User if pervious visit date is more than 7 days before current visit date
      if (this.previousEncounter && !this.dos_ack) {
        if (this.confirmVisitDate(this.previousEncounter.visit_date)) {
          const noteDialog = `The entered date of service is over a week beyond the patient's previous visit date. Please double check that there is not another visit to enter between ${this.formatDate(this.previousEncounter.visit_date)} and ${this.formatDate(this.encounter.visit_date)}.`
          this.$root.confirm({
            title: 'Notice: Date of Service',
            body: noteDialog,
            large: true,
            cancel: false,
          })

          // Ensures dialogue doesn't appear twice for user
          this.dos_ack = 1
          this.$root.previousPath = this.$route.path

          return
        }
      }

      // Add some required values to the encounter object
      this.encounter.patient_id = this.patient.id

      // Check to see if it's a duplicate encounter
      const isDuplicate = this.$store.getters['encounters/isDuplicateEncounter'](this.encounter, this.$authUser.id())

      // Verify we're not trying to create an encounter prior to another encounter
      const isPrior = this.$store.getters['encounters/isPriorEncounter'](this.encounter)

      if (isDuplicate) {
        this.$root.confirm({
          title: 'Duplicate Encounter Found, Redirect?',
          body: 'A duplicate encounter was found, do you wish to be redirected to the encounter?',
          cancel: 'No',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.$store.dispatch('loading', true)
            this.$router.push({
              name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).encounterRoute,
              query: { id: isDuplicate },
            })
          }
        })
      } else if (isPrior) {
        this.$root.confirm({
          titleIconColor: 'error',
          title: 'Invalid visit date!',
          body: 'You can\'t create an encounter with a visit date prior to another encounter. Encounters must occur in chronological order.',
          cancel: false,
        })
      } else {
        this.generateEncounter(this.previousEncounter)
          .then(() => {
            this.navigateToPage()
          })
      }
    },
    cancelEncounter() {
      if (this.formValid) {
        this.$root.confirm({
          title: 'Cancel?',
          body: 'Are you sure you wish to cancel creating an encounter?',
          cancel: 'No',
          confirm: 'Yes',
        }).then(result => {
          if (result) {
            this.$store.dispatch('route/back')
          }
        })
      } else {
        this.$store.dispatch('route/back')
      }
    },
    navigateToPage() {
      if (this.isQuickEntry) {
        // If quick entry, route to quick entry listing
        this.$store.dispatch('notify', { value: 'Quick entry created successfully.', color: 'success' })
        this.$router.push({
          name: 'quick-entry',
          query: {
            facility: this.$route.params.placeOfServiceId,
            visit_date: this.encounter.visit_date,
          },
        })
      } else if (this.encounter.is_deferred_care) {
        // If deferred care, go to the encounter note
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).noteRoute,
          query: { id: this.encounter.id },
        })
      } else {
        // Route to the new encounter
        this.$store.dispatch('notify', { value: 'Encounter created successfully.', color: 'success' })
        this.$store.dispatch('loading', true)
        this.$router.push({
          name: this.$store.getters['baseData/practiceTypeFromId'](this.encounter.practice_type_id).encounterRoute,
          query: { id: this.encounter.id },
        })
      }
    },
    confirmVisitDate(prevDate) {
      const currDos = dayjs(this.encounter.visit_date).startOf('day')

      // Add a week to the previous date of service
      const weekAfterPrevDos = dayjs(prevDate).startOf('day').add(1, 'week')

      // Notify the user if the selected date is more than 1 week after the prior visit
      return currDos.isAfter(weekAfterPrevDos, 'day')
    },
  },
}
</script>
<style lang="scss">
  .chip {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
    min-height: fit-content;
    padding: 10px; /* Adjust padding as needed */
    box-sizing: border-box; /* Ensure padding and width work as expected */
    justify-content: space-around;
  }

</style>
